import Image from '../images/values.jpg';
import SectionHead from './SectionHead';
import {GiCutDiamond} from 'react-icons/gi';
import { values } from '../data';
import Card from '../UI/Card';


const Values = () => {
  return (
    <section className='values'>
        <div className="container values__container">
            <div className="values__left">
                <div className="values__image">
                    <img src={Image} alt="Values" />
                </div>
            </div>
            <div className="values__right">
                <SectionHead icon={<GiCutDiamond />} title="Values" />
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Hic reiciendis aliquam at cumque nam ipsa, 
                   ducimus perspiciatis fugiat numquam necessitatibus quos accusamus cupiditate rerum fugit ea culpa 
                   id esse veniam?
                </p>
                <div className="values__wrapper">
                    {
                        values.map(({id, icon, title, desc}) => (
                            <Card key={id} className="values__value">
                               <span>{icon}</span>
                               <h4>{title}</h4>
                               <small>{desc}</small>
                            </Card>
                            )
                        )
                    }
                </div>
            </div>
        </div>
    </section>
  )
}

export default Values